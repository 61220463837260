import Footer from '@/Components/Footer';
import Providers from '@/providers';
import { PrimeReactProvider } from 'primereact/api';
import { PropsWithChildren } from 'react';

export default function HeaderlessLayout({ children }: PropsWithChildren) {
    return (
        <>
            <PrimeReactProvider>
                <Providers>
                    <main className="main-height container-xs relative flex items-start justify-center">
                        {children}
                    </main>
                    <Footer />
                </Providers>
            </PrimeReactProvider>
        </>
    );
}
